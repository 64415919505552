import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { createPortal } from 'react-dom';
import GridTableRow, { TGridTableRowData } from '@@components/GridTableRow/GridTableRow';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';

type TGridTableType = 'default' | 'gemstone';

type TGridTableProps = {
    readonly rowsData: TGridTableRowData[];
    readonly numCols: number;
    readonly continueReadingLabel?: string;
    readonly tableType?: TGridTableType;
};

const GridTable: React.FC<TGridTableProps> = ({ rowsData, numCols, continueReadingLabel, tableType = 'default' }) => {
    const { locale }: TLocaleContext = useContext(LocaleContext);
    const [headerRow, ...restRows]: TGridTableRowData[] = rowsData;

    let headerRowLabel: string = '',
        headerRowValue: string = '',
        giaReportNo: string = '';

    if (tableType === 'gemstone') {
        headerRowLabel = headerRow?.label ?? 'GIA Report Number';
        headerRowValue = headerRow?.value ?? '';
        giaReportNo = headerRow?.value ?? '';
        rowsData = restRows;
    }

    const [currentTooltipIndex, setCurrentTooltipIndex] = useState<number>(-1);
    const lastTooltipIndex: number = rowsData.length - 1;
    const tooltipInfoIconRef = useRef<HTMLButtonElement>(null);

    const isTooltipTrackPlaying: boolean = currentTooltipIndex >= 0;

    function resetTooltips(): void {
        setCurrentTooltipIndex(-1);
        if (tooltipInfoIconRef.current) {
            tooltipInfoIconRef.current.focus();
        }
    }

    const handleKeyDown = (event: KeyboardEvent): void => {
        if (event.key === 'Escape') {
            resetTooltips();
        }
    };

    function handleInfoIconClick(): void {
        if (!isTooltipTrackPlaying) {
            setCurrentTooltipIndex(0);
        } else {
            resetTooltips();
        }
    }

    function handleNextTooltipClick(): void {
        if (currentTooltipIndex === lastTooltipIndex) {
            resetTooltips();
        } else {
            setCurrentTooltipIndex(prevIndex => prevIndex + 1);
        }
    }

    function showTooltipbyIndex(index: number): void {
        setCurrentTooltipIndex(index);
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <>
            <div className={`xtable xtable-${tableType}`}>
                <div className='xtable-body position-relative'>
                    {numCols === 2 && (
                        <button
                            ref={tooltipInfoIconRef}
                            className='xtable-info position-absolute'
                            aria-label='More info'
                            onClick={handleInfoIconClick}
                        ></button>
                    )}
                    {numCols === 2 && tableType === 'gemstone' && (
                        <div className='xtable-head xtable-row' style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                            <div className='xtable-cell font-weight-bold position-relative'>
                                <span>{headerRowLabel}</span>
                            </div>
                            <div className='xtable-cell font-weight-bold position-relative d-flex flex-column'>
                                <span>{headerRowValue}</span>
                            </div>
                        </div>
                    )}
                    {numCols === 2 &&
                        rowsData.map((row: TGridTableRowData, index: number) => (
                            <GridTableRow
                                key={row.label.toUpperCase()}
                                row={row}
                                index={index}
                                currentTooltipIndex={currentTooltipIndex}
                                totalRows={rowsData.length}
                                numCols={numCols}
                                onNextTooltipClick={handleNextTooltipClick}
                                resetTooltips={resetTooltips}
                                showTooltipByIndex={showTooltipbyIndex}
                            />
                        ))}
                    {numCols === 2 && tableType === 'gemstone' && (
                        <div className='xtable-row' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                            <div className='xtable-cell position-relative text-center text-md-left my-2'>
                                <Link
                                    to={`/report-check?reportno=${giaReportNo}&locale=${locale}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {continueReadingLabel ?? 'Continue Reading Full GIA Report'}
                                </Link>
                            </div>
                        </div>
                    )}
                    {numCols === 1 &&
                        rowsData.map(row => (
                            <div
                                key={row.label.toUpperCase()}
                                className='xtable-row'
                                style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}
                            >
                                <div className='xtable-cell position-relative d-flex flex-column'>
                                    <span>{row.value}</span>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            {isTooltipTrackPlaying &&
                createPortal(
                    <div className='xtooltip-backdrop' onClick={() => resetTooltips()}></div>,
                    document.getElementById('report-check')!
                )}
        </>
    );
};

export type { TGridTableType, TGridTableProps };
export default GridTable;
