/**
 * An enumeration of locales
 * @enum
 */
export enum Locales {
    en_US = 'en_US',
    zh_CN = 'zh_CN',
    ja_JP = 'ja_JP',
}

/**
 * An enumeration of locales exported as constant readonly strings
 *
 * @const
 * @enum
 */
export const Locale = {
    ...Locales,
} as const;

/**
 * A string literal type representing a type of locale
 *
 * @type
 */
export type TLocale = keyof typeof Locales;

/**
 * A type representing a other string values derived from or shared with a Locale
 * @type
 */
export type TLocaleConfig = {
    path: string;
    abbr: string;
    bcp47: string;
    userFriendly: string;
};

/**
 * A helpful mapping of locales to their associated string values
 * @const
 * @enum
 */
export const LocaleConfigs: { [key in Locales]: TLocaleConfig } = {
    [Locales.en_US]: {
        path: '',
        abbr: 'en',
        bcp47: 'en-US',
        userFriendly: 'English U.S.',
    },
    [Locales.zh_CN]: {
        path: 'CN/',
        abbr: 'cn',
        bcp47: 'zh-CN',
        userFriendly: '简体中文',
    },
    [Locales.ja_JP]: {
        path: 'JP/',
        abbr: 'jp',
        bcp47: 'ja-JP',
        userFriendly: '日本語',
    },
} as const;
