import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import 'dayjs/locale/zh-cn';
import React, { useContext } from 'react';
import parse from 'html-react-parser';
import { Tab, Tabs } from 'react-bootstrap';
import { useLoaderData } from 'react-router-dom';
import AdditionalInformation from '@@components/AdditionalInformation/AdditionalInformation';
import AGSReport from '@@components/AGSReport/AGSReport';
import DataError, { TErrorCode } from '@@components/DataError/DataError';
import Fancybox from '@@components/Fancybox/Fancybox';
import GradingReport from '@@components/GradingReport/GradingReport';
import ImageLoader from '@@components/ImageLoader/ImageLoader';
import JewelryGradingReport from '@@components/JewelryGradingReport/JewelryGradingReport';
import LabGrownBanner from '@@components/LabGrownBanner/LabGrownBanner';
import ProvenanceAccordion from '@@components/ProvenanceAccordion/ProvenanceAccordion';
import ReportBasicInfo from '@@components/ReportBasicInfo/ReportBasicInfo';
import ReportSupplemental from '@@components/ReportSupplemental/ReportSupplemental';
import ReportSupplementals from '@@components/ReportSupplementals/ReportSupplementals';
import RightRail from '@@components/RightRail/RightRail';
import SupplementalsAccordion from '@@components/SupplementalsAccordion/SupplementalsAccordion';
import SVSAccordion from '@@components/SVSAccordion/SVSAccordion';
import { ReportTypeCode, TReportTypeCode } from '@@config/reportTypeCode';
import { TLocale } from '@@config/locale';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import {
    getAWSEnv,
    getElectronicReportURL,
    getLocaleDayJS,
    getReportCheckLandingURL,
    parseLabels,
    pushDataLayerEvent,
} from '@@utils/utils.ts';

type LoaderData = {
    data?: any;
    error?: { code: TErrorCode; message: string };
    locale: TLocale;
    reportno?: string;
};

const Results: React.FC = () => {
    const { locale }: TLocaleContext = useContext(LocaleContext);
    const { data = {}, error, reportno: requestedReportNo } = useLoaderData() as LoaderData;

    if (error?.code === '401') {
        window.location.href = getReportCheckLandingURL(requestedReportNo, locale);
        return null;
    }

    if (error) {
        return <DataError errorCode={error.code} fallbackMessage={error.message} />;
    }

    const { labels, i18n }: ReturnType<typeof parseLabels> = parseLabels(data);
    const REPORT_TYPE_CODE: TReportTypeCode = data.REPORT_TYPE_CODE;
    const REPORT_NO: string = data.REPORT_NO;
    const REPORT_DT: Date = new Date(data.REPORT_DT);
    const REPORT_DT_FORMATTED = dayjs(REPORT_DT).locale(getLocaleDayJS(locale)).format('MMM. D, YYYY');
    const PDF_URL: string = data.PDF_URL;
    const WEIGHT: string = data.WEIGHT;
    const CUT_GRADE: string = data?.['CUT_GRADE'] ?? '';
    const hasCutGrade: boolean = CUT_GRADE !== '';

    if (getAWSEnv() === 'prod') {
        pushDataLayerEvent('reportType', data.REPORT_TYPE ?? '');
        pushDataLayerEvent('reportDate', data.REPORT_DT ?? '');
    }

    /**
     * The primary download URL is determined by the report type code and whether or not a PDF is available.
     */
    let primaryCTA: URL | null = null;
    if (REPORT_TYPE_CODE === 'DER') {
        // For Diamond eReport we have to curate a custom URL based on the report number and weight.
        primaryCTA = getElectronicReportURL(REPORT_NO, WEIGHT);
    } else if (PDF_URL) {
        // Default for most reports is the PDF_URL.
        primaryCTA = new URL(PDF_URL);
    }

    // Boolean currently only for determining whether to display the subtitle "FOR NATURAL DIAMOND" below report type.
    const isNaturalDiamond = ['DD', 'CI', 'CD', 'FC', 'DER', 'DG', 'DOR', 'CDOR'].includes(REPORT_TYPE_CODE);
    // Boolean for whether to display the full-width Lab-Grown Banner above report info.
    const isLabGrown = ['LGDOSS', 'LGDG', 'LGCD', 'LGCI', 'LGDR'].includes(REPORT_TYPE_CODE);
    // const isLegacyDOR = REPORT_TYPE_CODE === 'DOR' && REPORT_DT < new Date('02/17/2021');
    const isJewelryLayout = ['JG'].includes(REPORT_TYPE_CODE);

    const isDefaultLayout = REPORT_TYPE_CODE in ReportTypeCode && !isJewelryLayout;

    const showTabs: boolean =
        data?.['IS_AGS'] === 'TRUE' ||
        data?.['COR_DATA']?.[0]?.['BODY_JSON'].length > 0 ||
        data?.['GROWTH_METHOD_DATA']?.[0]?.['BODY_JSON'].length > 0 ||
        data?.['SVS_DATA']?.[0]?.['BODY_JSON'].length > 0 ||
        data?.['TREATMENTS_DATA']?.[0]?.['BODY_JSON'].length > 0 ||
        (data?.['TRACR_DATA']?.length > 0 && data?.['TRACR_ID']);

    if (isJewelryLayout) {
        return (
            <main id='main-container' className='container-default my-4 mx-auto' style={{ minHeight: 'auto' }}>
                <section className='d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-start'>
                    <ReportBasicInfo reportNumber={REPORT_NO} reportDateFormatted={REPORT_DT_FORMATTED} i18n={i18n} />

                    <div className='d-none d-lg-block'>
                        <ReportSupplementals
                            reportTypeCode={REPORT_TYPE_CODE}
                            reportNumber={REPORT_NO}
                            primaryCTA={primaryCTA}
                            labels={labels}
                            i18n={i18n}
                        />
                    </div>
                </section>

                <hr className='dotted-double mt-sm-1 mt-md-3' />

                {/* Main Report Sleeve */}
                <JewelryGradingReport data={data} labels={labels} />

                <AdditionalInformation reportTypeCode={REPORT_TYPE_CODE} />
            </main>
        );
    }

    if (isDefaultLayout) {
        return (
            <main id='main-container' className='container-default my-4 mx-auto' style={{ minHeight: 'auto' }}>
                <section className='d-flex flex-column flex-md-row justify-content-md-between align-items-md-start'>
                    <ReportBasicInfo reportNumber={REPORT_NO} reportDateFormatted={REPORT_DT_FORMATTED} i18n={i18n} />

                    <div className='d-none d-md-block'>
                        <ReportSupplemental
                            reportTypeCode={REPORT_TYPE_CODE}
                            reportNumber={REPORT_NO}
                            primaryCTA={primaryCTA}
                            reportDAC={data.DIGITAL_CARD}
                            reportDTL={data.DTL_PDF_URL}
                            reportDTLP={data.DTLP_PDF_URL}
                            reportDTTL={data.DTTL_PDF_URL}
                            i18n={i18n}
                        />
                    </div>
                </section>
                <hr className='dotted-double mt-sm-1 mt-md-3' />
                <div className='row report-details-and-sidebar no-gutters'>
                    {isLabGrown && (
                        <div className='col-12'>
                            <LabGrownBanner reportTypeCode={REPORT_TYPE_CODE} i18n={i18n} />
                        </div>
                    )}
                    <div className='col-sm-12 col-md-9'>
                        <div className='row mt-3 mb-5'>
                            <div className='col'>
                                <h1 id='REPORT_TYPE' className='text-left bg-none letter-spacing-normal pb-0 mb-0'>
                                    {parse(data?.['REPORT_TYPE'])}
                                </h1>
                                {isNaturalDiamond && (
                                    <span id='REPORT_TYPE_SUBTITLE' className='text-uppercase font-weight-bold'>
                                        FOR NATURAL DIAMOND
                                    </span>
                                )}
                            </div>
                            {data?.['DGTIMG'] && (
                                <div className='col-sm-12 col-md-3'>
                                    <div className='mr-0 mr-md-3'>
                                        <Fancybox>
                                            <a href={data['DGTIMG']} data-fancybox>
                                                <ImageLoader
                                                    className='img-fluid'
                                                    src={data['DGTIMG']}
                                                    minHeight={125}
                                                    alt='...'
                                                />
                                            </a>
                                        </Fancybox>
                                    </div>
                                </div>
                            )}
                        </div>

                        {!showTabs && <GradingReport data={data} i18n={i18n} />}

                        {showTabs && (
                            <Tabs
                                defaultActiveKey='report-details'
                                className='supp-tabs d-flex justify-content-center text-uppercase my-3 border-0'
                            >
                                <Tab eventKey='report-details' title='Report Details'>
                                    <GradingReport data={data} i18n={i18n} />
                                </Tab>

                                {data['COR_DATA'] && (
                                    <Tab eventKey='country-of-origin' title='Country of Origin'>
                                        <SupplementalsAccordion supplementalContent={data['COR_DATA']} />
                                    </Tab>
                                )}

                                {data?.['TREATMENTS_DATA'] && !data.reportData?.['GROWTH_METHOD'] && (
                                    <Tab eventKey='treatment' title='Treatment'>
                                        <SupplementalsAccordion supplementalContent={data['TREATMENTS_DATA']} />
                                    </Tab>
                                )}

                                {data?.['GROWTH_METHOD_DATA'] && (
                                    <Tab eventKey='growth-method' title='Growth Method'>
                                        <SupplementalsAccordion supplementalContent={data['GROWTH_METHOD_DATA']} />
                                    </Tab>
                                )}

                                {data?.['SVS_DATA'] && (
                                    <Tab
                                        eventKey='svs'
                                        title={parse(`<span>GIA<sup>®</sup> Source Verification Service</span>`)}
                                    >
                                        <SVSAccordion
                                            svsName={data?.['SVS_NAME'] ?? ''}
                                            supplementalContent={data['SVS_DATA']}
                                        />
                                    </Tab>
                                )}

                                {data?.['TRACR_DATA'] && (
                                    <Tab eventKey='provenance' title='Provenance'>
                                        <ProvenanceAccordion
                                            tracrId={data?.['TRACR_ID']}
                                            provenanceName={data?.['PROVENANCE_NAME']}
                                            supplementalContent={data['TRACR_DATA']}
                                        />
                                    </Tab>
                                )}

                                {data?.['IS_AGS'] === 'TRUE' && (
                                    <Tab eventKey='ags' title={parse(`AGS Ideal<sup>&reg;</sup> Report`)}>
                                        <AGSReport
                                            REPORT_DT_FORMATTED={REPORT_DT_FORMATTED}
                                            REPORT_NO={REPORT_NO}
                                            AGS_SHAPE={data?.['AGS_SHAPE']}
                                            AGS_MEASUREMENTS={data?.['AGS_MEASUREMENTS']}
                                            AGS_CARAT_WEIGHT={data?.['AGS_CARAT_WEIGHT']}
                                            AGS_LIGHT_PERFORMANCE_GRADE={data?.['AGS_LIGHT_PERFORMANCE_GRADE']}
                                            AGS_BRIGHTNESS={data?.['AGS_BRIGHTNESS']}
                                            AGS_FIRE={data?.['AGS_FIRE']}
                                            AGS_CONTRAST={data?.['AGS_CONTRAST']}
                                            AGS_PDF_URL={data?.['AGS_PDF_URL']}
                                            AGS_LIGHTIMG={data?.['AGS_LIGHTIMG']}
                                        />
                                    </Tab>
                                )}
                            </Tabs>
                        )}
                    </div>
                    <div className='w-100 d-sm-block d-md-none my-4'>
                        <ReportSupplemental
                            reportTypeCode={REPORT_TYPE_CODE}
                            reportNumber={REPORT_NO}
                            primaryCTA={primaryCTA}
                            reportDAC={data.DIGITAL_CARD}
                            reportDTL={data.DTL_PDF_URL}
                            reportDTLP={data.DTLP_PDF_URL}
                            reportDTTL={data.DTTL_PDF_URL}
                            i18n={i18n}
                        />
                    </div>
                    <aside id='right-rail' className='col-sm-12 col-md-3'>
                        <RightRail reportTypeCode={REPORT_TYPE_CODE} hasCutGrade={hasCutGrade} />
                    </aside>
                </div>
            </main>
        );
    }

    return <main className='container-default my-4 mx-auto' style={{ minHeight: 'auto' }}></main>;
};

export default Results;
